// export const API_URL = 'https://2383-103-177-173-40.in.ngrok.io';

export const API_URL = 'http://api.fruxinfoapi.in/';
// export const API_URL = 'http://128.199.20.237:7000/';
// export const API_URL = 'https://whatsapp.fruxinfoapi.in/';

// export const socket_url = 'https://2383-103-177-173-40.in.ngrok.io'; 
// export const socket_url = 'https://api.fruxinfoapi.in'; 
// export const socket_url = 'https://whatsapp.fruxinfoapi.in'; 
export const socket_url = 'http://193.203.160.86:7000'; 

export const userType = "Admin".toLowerCase();

// export const userType = "customer".toLowerCase();

// ProxyPreserveHost On
// ProxyVia Full
// ProxyPass / http://128.199.26.206:7000/
// ProxyPassReverse / http://128.199.26.206:7000/
